import { useState, useEffect } from "preact/hooks";
import { Signal } from "@preact/signals";
import PanelRightHeader from "./PanelRightHeader.tsx";
import ApiDetailsHeader from "./ApiDetailsHeader.tsx";
import ApiDetailsParameters from "./ApiDetailsParameters.tsx";
import ApiDetailsResponses from "./ApiDetailsResponses.tsx";
import TryItOut from "./TryItOut.tsx";
import { CurlCommand } from "./CurlCommand.tsx";
import ApiDetailsRequestBody from "./ApiDetailsRequestBody.tsx";

// Import ServersArray from config
import { ServersArray } from "../../routes/config.ts";

interface PanelRightIslandProps {
  data: any;
  selectedPath: Signal<string | null>;
  selectedMethod: Signal<string | null>;
}

export default function PanelRightIsland({ data, selectedPath, selectedMethod }: PanelRightIslandProps) {
  const path = selectedPath.value;
  const method = selectedMethod.value;
  const description = path && method
    ? data?.paths?.[path]?.[method]?.description || "Keine Beschreibung verfügbar"
    : "Loading...";

  // Get servers from data and combine with imported ServersArray
  const dataServers = data?.servers || [];
  const combinedServers = dataServers;
  //const combinedServers = [...dataServers, ...ServersArray];  // Combine both arrays

  // Set initial selected server from combined servers
  const [selectedServer, setSelectedServer] = useState(combinedServers.length > 0 ? combinedServers[0] : null);
  const [selectedParameters, setSelectedParameters] = useState<any[]>([]);
  const [selectedResponses, setSelectedResponses] = useState<any[]>([]);
  const [requestBody, setRequestBody] = useState<string>("");
  const [statusCode, setStatusCode] = useState<number | null>(null);  // New state for statusCode

  const endpoint = path && method ? data.paths[path]?.[method] : undefined;

  const handleParameterChange = (parameters: any[]) => setSelectedParameters(parameters);
  const handleResponseChange = (responses: any[]) => setSelectedResponses(responses);
  const handleRequestBodyChange = (body: string) => setRequestBody(body);
  const handleServerChange = (server: { url: string; description: string }) => setSelectedServer(server);

  const handleStatusCodeChange = (status: number) => setStatusCode(status);  // New handler to update statusCode

  useEffect(() => {
    setSelectedParameters([]);
    setSelectedResponses([]);
    setRequestBody("");
    setStatusCode(null);  // Reset statusCode when path or method changes
  }, [selectedPath.value, selectedMethod.value]);

  return (
    <div class="p-0">
      {combinedServers.length > 0 && (
        <PanelRightHeader servers={combinedServers} selectedServer={selectedServer} onServerChange={handleServerChange} />
      )}
      {path && method ? (
        <div class="flex-1 overflow-y-auto p-4">
          <ApiDetailsHeader selectedPath={selectedPath} selectedMethod={selectedMethod} description={description} />

          <CurlCommand
            selectedServer={selectedServer}
            selectedPath={path}
            selectedMethod={method}
            selectedParameters={selectedParameters}
            selectedResponses={selectedResponses}
            methods={data?.paths?.[path]}
          />

          <ApiDetailsParameters
            parameters={endpoint?.parameters}
            onParameterChange={handleParameterChange}
            selectedPath={selectedPath.value}
            selectedMethod={selectedMethod.value}
          />

          {endpoint?.requestBody && (
            <ApiDetailsRequestBody onRequestBodyChange={handleRequestBodyChange} />
          )}

          <ApiDetailsResponses
            responses={endpoint?.responses}
            onResponseChange={handleResponseChange}
            selectedPath={selectedPath.value}
            selectedMethod={selectedMethod.value}
            statusCode={statusCode}  // Pass the status code here
          />

          <TryItOut
            selectedServer={selectedServer}
            selectedPath={path}
            selectedMethod={method}
            selectedParameters={selectedParameters}
            selectedResponses={selectedResponses}
            requestBody={requestBody} 
            onStatusCodeChange={handleStatusCodeChange}  // Pass the handler to update statusCode
          />
        </div>
      ) : (
        <div class="flex-1 overflow-y-auto p-4">
          <p class="text-orange-500 font-semibold p-6 mt-12">
          Select an API endpoint to view the corresponding API details.
          </p>
        </div>
      )}
    </div>
  );
}
